import Auth from '@aws-amplify/auth';


const init = (): void => {
  Auth.configure({
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.CUSTOMER_ACCOUNT_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.CUSTOMER_ACCOUNT_COGNITO_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.CUSTOMER_ACCOUNT_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.CUSTOMER_ACCOUNT_WEB_CLIENT_ID,
  });

  if (process.env.NODE_ENV === 'development') {
    // Amplify.Logger.LOG_LEVEL = 'DEBUG';
  }
};

export default { init };
