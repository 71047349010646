import React, { useEffect, useState } from 'react';
import {
  Alert, Link, LoadingIndicator,
} from '@simplybusiness/mobius';
import { fireAccountEvent } from 'services/TrackingService';
import { getGoogleWalletLink } from '../../api/wallets';
import { TermsAgreement } from '../index';
import GoogleWalletButtonStacked from './GoogleWalletButtonStacked';
import GoogleWalletButton from './GoogleWalletButton';

type Props = {
  walletImageProps: {};
  loadingContainerProps: {};
  downloadedFrom: string;
  productType: string;
  showTerms?: boolean;
}

const fireAccountAddToWalletEvent = (
  downloadedFrom: string, productType: string,
): void => {
  fireAccountEvent('added_qr_code_to_wallet', {
    walletType: 'Google',
    downloadedFrom,
    productType,
  });
};

const buttonImage = (coiPage: boolean, downloadedFrom: string, productType: string): JSX.Element => {
  if (coiPage) {
    return (
      <GoogleWalletButton
        data-testid="google-wallet-img"
        onClick={() => {
          fireAccountAddToWalletEvent(downloadedFrom, productType);
        }}
      />
    );
  }

  return (
    <GoogleWalletButtonStacked
      data-testid="google-wallet-img"
      onClick={() => {
        fireAccountAddToWalletEvent(downloadedFrom, productType);
      }}
    />
  );
};

const GoogleWalletContainer = (props: Props): JSX.Element => {
  const {
    walletImageProps, loadingContainerProps, downloadedFrom, productType, showTerms = false,
  } = props;

  const WalletComponent = (walletHref): JSX.Element => (
    <>
      <Link href={walletHref}>
        <div {...walletImageProps}>
          {buttonImage(showTerms, downloadedFrom, productType)}
        </div>
      </Link>
      {showTerms && <TermsAgreement textAlign="center" />}
    </>
  );

  const Loading = (): JSX.Element => (
    <div {...loadingContainerProps}>
      <LoadingIndicator />
      <p style={{ margin: '0px' }}>
        Loading Google Wallet...
      </p>
    </div>
  );

  const FailedToLoad = (): JSX.Element => (
    <Alert>
      It is not possible to generate a Google Wallet link at the moment.
    </Alert>
  );

  const [Component, setComponent] = useState<JSX.Element>(Loading);

  useEffect(() => {
    getGoogleWalletLink()
      .then(url => setComponent(WalletComponent(url)))
      .catch((error) => {
        setComponent(FailedToLoad);

        if (error.name !== 'FetchError') throw error;
      });
  }, []);

  return Component;
};

export default GoogleWalletContainer;
