import React, { ReactNode } from 'react';
import styled from '@emotion/styled';


const getBackgroundColor = (props): string => {
  const { warning, error } = props;

  if (error) return 'var(--color-error-background)';
  if (warning) return 'var(--color-warning-background)';

  return 'var(--color-valid-background)';
};

const getBorderColor = (props): string => {
  const { warning, error } = props;

  if (error) return 'var(--color-error)';
  if (warning) return 'var(--color-warning)';

  return 'var(--color-valid)';
};

type TypeIndicator = string | undefined;

const StyledContainer = styled.div<{ error: TypeIndicator, warning: TypeIndicator, overrideColor: string }>`
  border-radius: var(--radius-1);
  word-break: break-word;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  border-left: 4px solid ${getBorderColor};
  background: ${getBackgroundColor};
  color: var(--color-text-medium);
  padding: 8px 8px 8px 12px;

  a {
    color: ${props => props.overrideColor ? props.overrideColor : 'inherit'};
  }
`;

interface Props{
  children: ReactNode,
  className?: string,
  error?: boolean,
  warning?: boolean,
  overrideColor?: string,
  dataTestId?: string
}

const Message = ({
  children, className, error = false, warning = false, overrideColor = '', dataTestId = 'message',
}: Props): JSX.Element => (
  <StyledContainer
    className={className}
    error={error ? error.toString() : undefined}
    warning={warning ? warning.toString() : undefined}
    overrideColor={overrideColor}
    data-testid={dataTestId}
  >
    {children}
  </StyledContainer>
);

export default Message;
