import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';

import { snowplowDUID, trackUnStructEvent } from 'services/TrackingService';
import newRelic from './newRelic';
import getAirbrakeNotifier from '../services/AirbrakeNotifierService';

const CLIENT = Experiment.initialize(process.env.AMPLITUDE_DEPLOYMENT_KEY, { retryFetchOnFailure: false });

class Amplitude {
  duid: string;

  businessUnit: string;

  experimentClient: ExperimentClient;

  // since we have async starting requirements we need to use init() pattern
  // const newAmp = await Amplitude.init(duid, businessUnit);
  static async init() {
    const duid = await snowplowDUID();
    const businessUnit = process.env.BUSINESS_UNIT_CODE;

    return new Amplitude(duid, businessUnit);
  }

  private constructor(duid = '', businessUnit = '') {
    this.duid = duid;
    this.businessUnit = businessUnit;
    this.experimentClient = CLIENT;
  }

  async fetchExperiments(userProperties = {}) {
    let results = {};

    try {
      // this does not return anything, it just attaches the data to the Client object and puts it in Local Storage
      await this.experimentClient.fetch({
        device_id: this.duid,
        user_properties: {
          domain_user_id: this.duid || '', // empty string backup avoids TS error
          business_unit: process.env.BUSINESS_UNIT_CODE,
          ...userProperties,
        },
      });

      results = { ...this.experimentClient.all() };
    } catch (error: any) {
      newRelic.logError(error);
      await getAirbrakeNotifier().notify(error);
    }

    return results;
  }

  fireExposureEvent({ variant, flagKey }) {
    trackUnStructEvent(
      'iglu:com.simplybusiness/participant_exposed_to_ab_test/jsonschema/1-0-0',
      {
        variant,
        test_name: flagKey,
        participant_id: this.duid,
        participant_type: 'user',
        business_unit: this.businessUnit,
      },
    );
  }
}

export default Amplitude;
