import React from 'react';
import styled from '@emotion/styled';


const StyledPlaceholder = styled.div<Props>`

${props => `
  background: var(--color-background-light);
  width: ${props.width || 100}px;
  height: var(--size-md);
  margin-bottom: var(--size-sm);
`}

`;

interface Props {
  width: number;
  className?: string;
}

const Placeholder = ({
  width,
  className,
}: Props): JSX.Element => (
  <StyledPlaceholder width={width} className={className} />
);

export default Placeholder;
