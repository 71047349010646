import styled from '@emotion/styled';

// TODO: Maybe we should move this somewhere more general
export default styled.span({
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
});
