import { combineReducers } from 'redux';

import user from 'resources/user/ducks';
import ui from 'resources/ui/ducks';
import policies from 'resources/policies/ducks';
import signIn from 'resources/sign_in/ducks';

export default combineReducers({
  user,
  ui,
  policies,
  signIn,
});
