/* eslint-disable-next-line */
export class UnprocessableEntityError extends Error {
  code?: string;

  emailAddress?: string;

  url?: string;

  errors?: Array<{ message: string }>;

  static fromResponse = (responseBody, url = ''): UnprocessableEntityError => {
    const error = new UnprocessableEntityError(responseBody.message || 'Unprocessable Entity');

    error.code = responseBody.code;
    error.emailAddress = responseBody.emailAddress;
    error.url = url;
    error.errors = responseBody.errors;

    return error;
  };

  constructor(message) {
    super(message);

    this.name = 'UnprocessableEntityError';
  }
}

export class FetchError extends Error {
  static isNativeFetchError = (nativeError): Boolean => {
    if (! nativeError) return false;
    if (nativeError.name === 'NetworkError') return true;

    return [/failed to fetch/i, /load failed/i, /network\s?(error|connection was lost)/i].some(
      pattern => pattern.test(nativeError.message),
    );
  };

  static fromNativeError = (nativeError): FetchError => {
    const error = new FetchError(nativeError.message);

    ['stack', 'code', 'errno', 'syscall'].forEach(
      (property) => { if (nativeError[property]) error[property] = nativeError[property]; },
    );

    return error;
  };

  constructor(message) {
    super(message);

    this.name = 'FetchError';
  }
}
