import React from 'react';
import { Field } from 'formik';
import { TextField, PasswordField as MobiusPasswordField } from '@simplybusiness/mobius';
import errorMessages from 'config/validation/errorMessages';

interface FormFieldProps {
  id: string,
  label?: string,
  error?: any,
  name: string,
  onChange?: Function,
  onBlur?: Function,
  type?: string,
  className?: string,
  placeholder?: string,
}

const InputField = ({
  field, form: { touched = {}, errors = {}, setFieldValue },
  type, id, label, placeholder,
}: any): JSX.Element => {
  const errorMessage = touched[field.name] && errors[field.name];
  let validationState;

  if (touched[field.name]) {
    validationState = errorMessage ? 'invalid' : 'valid';
  }

  return (
    <TextField
      id={id}
      type={type}
      data-testid={`input-${field.name}`}
      label={label}
      {...field}
      validationState={validationState}
      onChange={event => setFieldValue(field.name, event.target.value, true)}
      errorMessage={errorMessage}
      placeholder={placeholder}
      aria-label={`input-${field.name}`}
    />
  );
};

const PasswordField = ({
  field,
  form: {
    touched = {}, errors = {}, values = {}, setFieldValue,
  },
  label,
  id,
}: any): JSX.Element => {
  let errorMessage = null;
  let validationState;
  const passwordErrors = errors[field.name];

  if (touched[field.name] && passwordErrors && passwordErrors.constructor === Array && passwordErrors.length > 0) {
    // case when we have custom validation (password field with multiple errors)
    errorMessage = passwordErrors.length === 1 ? passwordErrors[0] : errorMessages.password.generic;
  } else if (touched[field.name] && typeof errors[field.name] === 'string') {
    // simple password field
    errorMessage = passwordErrors;
  }
  if (values[field.name] == null) throw `Cannot retrieve the values for the field [${field.name}] please check its "name" property.`; // eslint-disable-line

  if (touched[field.name]) {
    validationState = errorMessage ? 'invalid' : 'valid';
  }

  return (
    <MobiusPasswordField
      {...field}
      label={label}
      data-testid={field.name}
      id={id}
      validationState={validationState}
      onChange={event => setFieldValue(field.name, event.target.value, true)}
      errorMessage={errorMessage}
    />
  );
};


const FormField = ({
  id, name, label, type = 'text', className, placeholder,
}: FormFieldProps): JSX.Element => (
  <Field
    className={className}
    id={id}
    name={name}
    label={label}
    component={type === 'password' ? PasswordField : InputField}
    placeholder={placeholder}
  />
);

export default FormField;
