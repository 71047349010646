import React from 'react';
import styled from '@emotion/styled';

import { Text } from '@simplybusiness/mobius';

import errorMessages from 'config/validation/errorMessages';

import Indicator from './Indicator';

interface Props {
  showIndicators: boolean, // to enable hiding/showing indicators. If there's any error, indicators will be shown anyway
  errors?: Array<string>, // Array of errors to be used to render the indicators
  className?: string
}

const Strong = styled.span`
  color: var(--color-primary);
`;

const Weak = styled.span`
  color: var(--color-error);
`;

const OverallIndicator = styled.div`
  margin-top: var(--size-xs);
  font-size: var(--font-size-small-paragraph);
`;

const PasswordStrength = ({
  showIndicators = false,
  errors = [],
  className,
}: Props): JSX.Element => {
  const allCriteriaMet = (errors.length === 0);

  const getOverallStrength = (): JSX.Element => {
    if (! showIndicators) return <Text elementType="span">-</Text>;
    if (errors.length === 0) return <Strong>Strong</Strong>;

    return <Weak>Weak</Weak>;
  };

  const passesCheck = (errorFieldName): boolean => (
    errors.indexOf(errorMessages.password[errorFieldName]) === -1
  );

  return (
    <div className={className} data-testid="password-strength">
      <OverallIndicator data-testid="overall-indicator">
        Password strength:
        {' '}
        {getOverallStrength()}
      </OverallIndicator>
      {! allCriteriaMet && showIndicators && (
      <React.Fragment>
        {/* eslint-disable max-len */}
        <Text elementType="p" style={{ marginBottom: '8px' }}>Make sure it has at least:</Text>
        <Indicator data-testid="lowerCase-indicator" key="lowerCase" positive={passesCheck('lowerCase')}>One lower case letter</Indicator>
        <Indicator data-testid="upperCase-indicator" key="upperCase" positive={passesCheck('upperCase')}>One upper case letter</Indicator>
        <Indicator data-testid="number-indicator" key="number" positive={passesCheck('number')}>One number</Indicator>
        <Indicator data-testid="minLength-indicator" key="minLength" positive={passesCheck('minLength')}>12 characters minimum</Indicator>
        {/* enable max-len */}
      </React.Fragment>
      )}
    </div>
  );
};

export default PasswordStrength;
