import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Text } from '@simplybusiness/mobius';

const StyledPage = styled.div`
  @media (min-width: 768px){
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  > h1:first-of-type, > h3:first-of-type{
    margin-top: 0px;
  }

  position: relative;
  padding: var(--size-md);
  @media (min-width: 768px){
    max-width: 790px;
    margin: 0 auto;
    padding: calc(var(--size-lg) + var(--size-xs));
  }
`;

export const StyledPageTitle = styled(Text)`
  text-align: center;
`;

interface Props {
  children: ReactNode,
  dataTestId?: string,
  title?: string,
  className?: string,
  backgroundColor?: string,
}

const PageSection = ({
  children, backgroundColor = 'inherit', dataTestId = 'page-section', title,
}: Props): JSX.Element => (
  <div style={{ backgroundColor }} data-testid={dataTestId} title={title}>
    <ContentWrapper>
      {title && <StyledPageTitle variant="h1">{title}</StyledPageTitle>}
      {children}
    </ContentWrapper>
  </div>
);

const filterChildrenForType = (children: ReactNode, type: React.ElementType) => (
  React.Children.toArray(children).filter(child => React.isValidElement(child) && child.type === type)
);

const Page = ({ children, className, title }: Props): JSX.Element => {
  const hasSections = filterChildrenForType(children, PageSection).length > 0;

  return (
    <StyledPage className={className} title={title}>
      {hasSections ? children : <PageSection title={title}>{children}</PageSection>}
    </StyledPage>
  );
};

Page.Section = PageSection;

export default Page;
