// make these types of errors look the same, so that NR groups them as the same error
function makeChunkLoadErrorsLookTheSame(error) {
  /* eslint-disable no-param-reassign */
  error.message = 'Chunk load error';
  /* eslint-enable no-param-reassign */
}

export const logError = (error: Error): void => {
  if (! window.newrelic) return;
  if (error?.name === 'ChunkLoadError') {
    makeChunkLoadErrorsLookTheSame(error);

    window.newrelic.noticeError(error, { originalMessage: error.message });
  } else {
    window.newrelic.noticeError(error);
  }
};

export default { logError };
