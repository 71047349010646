/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@simplybusiness/mobius';
import PhoneNumber from 'components/PhoneNumber';
import { trackLinkClick } from 'services/TrackingService';
import OpeningHours from './OpeningHours';

const BodyText = styled(Text)`
  color: var(--color-text-medium);
  font-size: var(--font-size-small-paragraph);
`;

const UKContactInfoContainer = styled.div`
  max-width: 790px;
  margin: 0 auto;
`;

const HeadingText = styled(Text)`
  line-height: 1.3;
  font-size: var(--font-size-paragraph);
  font-weight: bold;
  color: black;
`;

const phoneNumber = <PhoneNumber number="0333 207 0560" />;

const codyUrl = 'https://help.simplybusiness.co.uk/hc/en-gb/articles/14738013928349-Instant-help-with-our-chatbot';
const claimUrl = 'https://www.simplybusiness.co.uk/insurance-claims/';
const supportUrl = 'https://help.simplybusiness.co.uk/hc/en-gb/';

const linkTrack = (url: string, label: string) => () => trackLinkClick({ url, label });
/* eslint-disable max-len */
const codyLink = <a href={codyUrl} target="_blank" onClick={linkTrack(codyUrl, 'uk-contact-info-cody')} rel="noreferrer">Digital Assistant</a>;
const supportLink = <a href={supportUrl} target="_blank" onClick={linkTrack(supportUrl, 'uk-contact-info-support')} rel="noreferrer">"Help and Support"</a>;
const claimsLink = <a href={claimUrl} target="_blank" onClick={linkTrack(claimUrl, 'uk-contact-info-claims')} rel="noreferrer">"making a claim"</a>;
/* eslint-enable max-len */


const UKContactInfo = (): JSX.Element => (
  <UKContactInfoContainer>
    <HeadingText>Need some help?</HeadingText>
    <BodyText>
      {'You can also chat with our '}
      {codyLink}
      {' or find guidance within the '}
      {supportLink}
      {' pages.'}
    </BodyText>
    <BodyText>
      {'Our UK-based team of experts are ready to answer any questions on '}
      {phoneNumber}
      .
    </BodyText>
    <HeadingText>Opening hours</HeadingText>
    <OpeningHours />
    <HeadingText>Calling about a claim?</HeadingText>
    <BodyText>
      {"We're here 24/7, all year round on "}
      {phoneNumber}
      {', also via '}
      {claimsLink}
      {' online.'}
    </BodyText>
  </UKContactInfoContainer>
);

export default UKContactInfo;
