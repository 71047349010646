import React, { ReactNode } from 'react';
import getAirbrakeNotifier from 'services/AirbrakeNotifierService';
import newRelic from 'lib/newRelic';

interface ErrorReporterProps {
  children: ReactNode;
}

class ErrorReporter extends React.Component<ErrorReporterProps> {
  state = {
    hasError: false,
    suggestRefresh: false,
  };

  private airbrake: any;

  constructor(props) {
    super(props);
    this.airbrake = getAirbrakeNotifier();
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, suggestRefresh: error?.name === 'ChunkLoadError' });

    if (error?.name === 'ChunkLoadError') {
      newRelic.logError(error);
    } else {
      this.airbrake.notify({
        error,
        params: { info },
      });
    }
  }

  render() {
    const { hasError, suggestRefresh } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          {suggestRefresh && (/* eslint-disable no-restricted-globals, react/jsx-one-expression-per-line */
            <p>
              It seems like you're offline.<br />
              Please try and <button type="button" onClick={() => { location.reload(); }}>reload the page</button><br />
              Alternatively, sign in to your <a href={process.env.MANAGE_YOUR_POLICY_URL}>online account</a>
            </p>
          ) /* eslint-enable no-restricted-globals, react/jsx-one-expression-per-line */
          }
        </div>
      );
    }

    return children;
  }
}

export default ErrorReporter;
