import { SagaIterator } from 'redux-saga';
import { spawn } from 'redux-saga/effects';

import userRootSaga from 'resources/user/sagas';
import policiesRootSaga from 'resources/policies/sagas';

export default function* root(): SagaIterator {
  yield spawn(userRootSaga);
  yield spawn(policiesRootSaga);
}
