// action constants
export const SIGN_IN__DECRYPT_START = 'SIGN_IN__DECRYPT_START';
export const SIGN_IN__DECRYPT_SUCCESS = 'SIGN_IN__DECRYPT_SUCCESS';
export const SIGN_IN__DECRYPT_ERROR = 'SIGN_IN__DECRYPT_ERROR';

export interface SignInState {
  email?: string | null;
  token?: string | null;
  error?: string | null;
}

export const defaultSignInState: SignInState = {
  email: sessionStorage.getItem('signin/email'),
  token: null,
  error: null,
};

interface Action {
  type: string;
  payload?: any;
  meta?: Object;
  error?: boolean;
}

// action creators
export const decryptStart = (token): Action => ({
  type: SIGN_IN__DECRYPT_START,
  payload: token,
});

export const decryptSuccess = (email): Action => ({
  type: SIGN_IN__DECRYPT_SUCCESS,
  payload: email,
});

export const decryptError = (token): Action => ({
  type: SIGN_IN__DECRYPT_ERROR,
  payload: token,
});

export default (prevState = defaultSignInState, action) => {
  switch (action.type) {
    case SIGN_IN__DECRYPT_START: {
      const token = action.payload;

      return {
        token,
        email: null,
        error: null,
      };
    }
    case SIGN_IN__DECRYPT_SUCCESS: {
      const email = action.payload;

      sessionStorage.setItem('signin/email', email);

      return {
        token: null,
        email,
        error: null,
      };
    }
    case SIGN_IN__DECRYPT_ERROR: {
      return {
        token: null,
        email: null,
        error: action.payload.message,
      };
    }
    default:
      return prevState;
  }
};
