import React from 'react';
import { sanitizePhoneNumber } from 'lib/phoneNumbers';
import { useTranslation } from 'react-i18next';
import { Link } from '@simplybusiness/mobius';

const PhoneNumber = ({ number }: {number: string}) => {
  const { t } = useTranslation();
  const phoneNumberAsLink = `tel:${sanitizePhoneNumber(number, t('international_phone_prefix'))}`;

  return <Link href={phoneNumberAsLink}>{number}</Link>;
};

export default PhoneNumber;
