import '@babel/polyfill';
import 'whatwg-fetch';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import amplify from 'config/amplify';

import store from 'boot/store';
import setupI18n from './locales/i18n';
import App from './app';

import '@simplybusiness/theme-sb';

amplify.init();

setupI18n();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* @ts-ignore */}
      <App />
    </BrowserRouter>
  </Provider>,
);
