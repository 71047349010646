
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  startOfToday, subDays, addDays, isAfter, isBefore, format, parseISO,
} from 'date-fns';
import { Icon } from '@simplybusiness/mobius';
import { currentBusinessUnit, OpeningHoursForDay, SpecialCase } from 'lib/businessUnit';
import { chevronDown, chevronUp } from '@simplybusiness/icons';


const OpeningHoursListItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 240px;
  padding-bottom: 12px;
  font-size: var(--font-size-small-paragraph);
  color: var(--color-text-medium);
`;

const FirstListItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
`;

const ExpanderIcon = styled(Icon)`
  color: var(--color-primary);
`;

type OpeningHoursData = {
  label: string,
  hours: string,
}

const formatTime = (date: Date): String => format(date, 'h:mmbbb');

const openingHoursConfig = (day: string): OpeningHoursForDay | undefined => {
  const { openingHours } = currentBusinessUnit().contactCentre;
  const config = openingHours[day.toLowerCase()];

  if (! config && day !== 'Saturday' && day !== 'Sunday') {
    return openingHours.weekday;
  }

  return config;
};

function formatSpecialCase({ date, open, close }: SpecialCase): OpeningHoursData {
  if (! open || ! close) {
    return { label: format(new Date(date), 'd MMM'), hours: 'Closed' };
  }

  const opening = new Date(`${date}T${open}`);
  const closing = new Date(`${date}T${close}`);

  return {
    label: format(opening, 'd MMM'),
    hours: `${formatTime(opening)} - ${formatTime(closing)}`,
  };
}

function specialCaseForToday(): OpeningHoursData | undefined {
  const today = format(startOfToday(), 'yyyy-MM-dd');
  const specialCase = currentBusinessUnit().contactCentre.specialCases.find(({ date }) => date === today);

  if (! specialCase) {
    return undefined;
  }

  return formatSpecialCase(specialCase);
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const dayAbbreviations = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function regularOpeningHours(): OpeningHoursData[] {
  return days.map((day, index) => {
    let hours: string;
    const { open, close } = openingHoursConfig(day) || {};

    if (! open || ! close) {
      hours = 'Closed';
    } else {
      const opening = new Date(`2020-12-26T${open}`);
      const closing = new Date(`2020-12-26T${close}`);

      hours = `${formatTime(opening)} - ${formatTime(closing)}`;
    }

    return { label: dayAbbreviations[index], hours };
  });
}

function specialOpeningHours(): OpeningHoursData[] {
  const yesterday = subDays(startOfToday(), 1);
  const twoWeeksFromNow = addDays(startOfToday(), 14);

  const specialCasesInTheNextTwoWeeks = currentBusinessUnit().contactCentre.specialCases.filter(({ date }) => {
    const parsedDate = parseISO(date);

    return isAfter(parsedDate, yesterday) && isBefore(parsedDate, twoWeeksFromNow);
  });

  return specialCasesInTheNextTwoWeeks.map(formatSpecialCase);
}

const OpeningHoursItem = ({ label, hours }: OpeningHoursData): JSX.Element => (
  <OpeningHoursListItem data-testid={`${label}-li`}>
    <span>{`${label} `}</span>
    <span style={{ minWidth: '150px' }}>{hours}</span>
  </OpeningHoursListItem>
);

const OpeningHours = (): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(! expanded);

  const regularHours = regularOpeningHours();

  const today = specialCaseForToday() || regularHours[new Date().getDay()];
  const [sunday, monday, ...tuesdayThroughSaturday] = regularHours;
  const sortedOpeningHours = [...tuesdayThroughSaturday, sunday, ...specialOpeningHours()];

  const firstListItem = expanded ? monday : today;

  return (
    <ul style={{ padding: '0px' }} data-testid="opening-hours">
      <FirstListItem>
        <OpeningHoursItem {...firstListItem} />
        <button type="button" className="icon-button" onClick={toggleExpanded} data-testid="opening-hours-expander">
          <ExpanderIcon icon={expanded ? chevronUp : chevronDown} />
        </button>
      </FirstListItem>

      {
        expanded && sortedOpeningHours.map(openingHours => (
          <OpeningHoursItem key={openingHours.label} {...openingHours} />
        ))
      }
    </ul>
  );
};

export default OpeningHours;
