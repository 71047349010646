import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getAll } from 'api/policies';
import { fetchPoliciesComplete, POLICIES__FETCH } from './ducks';

export const fetchPoliciesSaga = function* fetchPolicies(): SagaIterator {
  try {
    const data = yield call(getAll);

    yield put(fetchPoliciesComplete(data));
  } catch (err: any) {
    yield put(fetchPoliciesComplete(err));
  }
};

export default function* rootUserSaga(): SagaIterator {
  yield takeLatest(POLICIES__FETCH, fetchPoliciesSaga);
}
