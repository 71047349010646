import { currentBusinessUnit } from './businessUnit';

export const AVALANCHE_TRACKER_NAME = 'sb-ava';
export const PV_VALANCHE_TRACKER_NAME = 'sb-ava-pv';

const trackerOptions = {
  appId: currentBusinessUnit().appId,
  platform: 'web',
  discoverRootDomain: true,
  encodeBase64: false,
  cookieLifetime: 86400, // 1 day
  stateStorageStrategy: 'cookie',
  eventMethod: 'post',
};

const fetchDomainuserId = (): Promise<string> => new Promise(resolve => (
  // eslint-disable-next-line func-names
  window.snowplow(function (this: any) {
    const value = this[AVALANCHE_TRACKER_NAME].getDomainUserId();

    resolve(value);
  })
));

function getCollectorUrl(): string {
  switch (process.env.BNW_ENV) {
    case 'production': {
      if (currentBusinessUnit().locale === 'en-GB') {
        return 'snowplow-collector.simplybusiness.co.uk';
      }

      return 'snowplow-collector.simplybusiness.com';
    }
    case 'staging':
    case 'integration':
    case 'test':
    case 'development':
    default: {
      if (currentBusinessUnit().locale === 'en-GB') {
        return 'snowplow-collector-staging.simplybusiness.co.uk';
      }

      return 'snowplow-collector-staging.simplybusiness.com';
    }
  }
}

class Snowplow {
  domainUserId?: string;

  constructor() {
    const COLLECTOR_URL = getCollectorUrl();

    // you can find the docs for these params here:
    // https://github.com/snowplow/snowplow/wiki/1-General-parameters-for-the-Javascript-tracker-v2.6#initialisation
    window.snowplow(
      'newTracker',
      PV_VALANCHE_TRACKER_NAME, // page view tracker
      COLLECTOR_URL,
      trackerOptions,
    );

    window.snowplow(
      'newTracker',
      AVALANCHE_TRACKER_NAME, // structured event tracker
      COLLECTOR_URL,
      trackerOptions,
    );
  }

  async getDomainUserId(): Promise<string> {
    if (! this.domainUserId) {
      this.domainUserId = await fetchDomainuserId();
    }

    return this.domainUserId;
  }
}

export default Snowplow;
