import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ConfigurationService from 'services/ConfigurationService';
import UKContactInfo from '../UKContactInfo';

type Props = {
  currentYear: string,
}

const StyledFooter = styled.footer`
  background: #ebebeb;
  padding: var(--size-md);
`;

const FooterText = styled.p`
  font-size: var(--font-size-small-paragraph);
  color: var(--color-text-medium);
  max-width: 790px;
  margin: 0 auto;
`;

const Footer = ({ currentYear }: Props): JSX.Element => {
  const { t } = useTranslation();
  const copyrightSymbol = String.fromCodePoint(0x00A9);

  return (
    <StyledFooter>
      {ConfigurationService.onlyUKtraffic() && ! ConfigurationService.isTest() && <UKContactInfo />}
      <FooterText>
        {`${copyrightSymbol} ${currentYear} Simply Business. `}
        {t('footer.company_info')}
      </FooterText>
    </StyledFooter>
  );
};

export default Footer;
