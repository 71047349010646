import { getApiBaseUrl, API_VERSION } from 'config/api';
import { getJwT } from 'services/AuthenticationService';
import fetchWrapper from './fetch';
import { AddressDetails } from './location';

// eslint-disable-next-line arrow-body-style
export const get = async (): Promise<any> => {
  return fetchWrapper(`${getApiBaseUrl()}/${API_VERSION}/current_contact_details/`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
  });
};

export const update = async (addressDetails: AddressDetails): Promise<unknown> => fetchWrapper(
  `${getApiBaseUrl()}/${API_VERSION}/current_contact_details/`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    method: 'PUT',
    body: JSON.stringify(addressDetails),
  },
);

export default { get, update };
