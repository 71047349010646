import * as React from 'react';

const Chevron = () => (
  /* eslint-disable max-len */
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7147 1.53527C13.1784 1.999 13.1785 2.75088 12.7147 3.21465L6.42997 9.5L12.7147 15.7854C13.1785 16.2491 13.1784 17.001 12.7147 17.4647C12.2509 17.9285 11.499 17.9284 11.0353 17.4647L4.24721 10.676C4.247 10.6757 4.24678 10.6755 4.24657 10.6753C3.9348 10.3637 3.75962 9.94087 3.75962 9.5C3.75962 9.05914 3.9348 8.63635 4.24657 8.32468C4.24678 8.32447 4.247 8.32426 4.24721 8.32405L11.0353 1.53535C11.499 1.07158 12.2509 1.07155 12.7147 1.53527Z" fill="#4632D8" />
  </svg>
  /* eslint-enable max-len */
);

export default Chevron;
