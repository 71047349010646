import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Text, Link, Button } from '@simplybusiness/mobius';
import { Form } from 'formik';
import InputOtp from '@onefifteen-z/react-input-otp';

import Message from 'components/Message';
import FormField from 'components/FormField';
import Arrow from 'components/Arrow';
import TextWithIcon from 'components/TextWithIcon';

const StyledFormField = styled(FormField)``;

const StyledButton = styled(Button)`
  width:100%;
`;

const StyledTitle = styled(Text)`
  color: var(--color-text);
`;

const StyledText = styled(Text)`
  color: var(--color-text-medium);
  line-height: var(--line-height-normal);
`;

const StyledMessage = styled(Message)`
  line-height: var(--line-height-normal);
`;

const StyledAuthWidget = styled(Form)`
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  word-break: break-word;

  @media (min-width: 768px){
    max-width: 370px;
  }

  ${StyledTitle}{
    margin-top: 0px;
  }

  ${StyledFormField}{
    margin-bottom: var(--size-sm);
  }

  ${StyledFormField}.password{
    margin-bottom: var(--size-xs);
  }

  ${StyledButton}:not(:last-child){
    margin-bottom: var(--size-sm);
  }

  ${StyledMessage}{
    margin-bottom: var(--size-sm);
  }

`;

const StyledBackLink = styled(Link)`
  display: inline-block;
  margin-top: var(--size-md);
  margin-bottom: var(--size-xs);
  color: var(--color-primary);
  cursor: pointer;
`;

const StyledOtcField = styled.div`
  .otpWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--size-lg);
    margin-top: var(--size-xs);
  }

  .otpInputWrapper {
    height: calc(var(--size-lg) + var(--size-xs));
    max-width: 40px;
    min-width: 40px;
    margin-right: var(--size-xs);
  }

  .otpInputWrapper:last-child {
    margin-right: 4px;
  }

  .otpInput {
    font-family: var(--font-family);
    font-size: var(--size-sm);
    width: 100%;
    height: inherit;

    border: 1px solid;
    border-radius: 10%;
    border-color: var(--color-border);
    outline: none;

    text-align: center;
    color:  var(--color-text);
    padding: 0 1px;
  }

  .otpInput:focus {
    border-color: var(--color-primary);
  }

  .otpInput:disabled {
    background-color: #eee;
    cursor: not-allowed;
  }
`;

interface Props{
  children: any,
  onSubmit?: Function,
  title?: string,
  error?: boolean,
  warning?: boolean,
  backButtonHandler?: () => void | boolean,
  message?: ReactNode,
}

class AuthWidget extends React.Component<Props> {
  static Button = StyledButton;

  static OtcField = ({
    value,
    onChange,
  }: { value: string, onChange: (val: string) => void }) => (
    <StyledOtcField>
      <InputOtp
        onChange={onChange}
        value={value}
        wrapperClass="otpWrapper"
        inputWrapperClass="otpInputWrapper"
        inputClass="otpInput"
      />
    </StyledOtcField>
  );

  static Field = StyledFormField;

  static Text = StyledText;

  render() {
    const {
      children, onSubmit, title, message, error, warning, backButtonHandler,
    } = this.props;

    return (
      /* @ts-expect-error Formik expects Form not to have an onSubmit but we need it and it works */
      <StyledAuthWidget onSubmit={onSubmit}>
        {message && (
          <StyledMessage error={!! error} warning={!! warning} dataTestId="auth-message">{message}</StyledMessage>
        )}
        <StyledTitle elementType="h3" data-testid="auth-title">{title}</StyledTitle>
        <div>
          {children}
        </div>
        {backButtonHandler && (
          <StyledBackLink
            onClick={backButtonHandler}
          >
            <TextWithIcon iconLeft={Arrow}>Back</TextWithIcon>
          </StyledBackLink>
        )}
      </StyledAuthWidget>
    );
  }
}

export default AuthWidget;
