import React from 'react';
import {
  Link, Text,
} from '@simplybusiness/mobius';
import configurationService from '../../services/ConfigurationService';

type Props = {
  textAlign: any;
}

const base = configurationService.onlyUKtraffic()
  ? 'https://www.simplybusiness.co.uk'
  : 'https://www.simplybusiness.com';

const TermsAgreement = (props: Props): JSX.Element => {
  const walletType = configurationService.isAndroid() ? 'Google' : 'Apple';

  return (
    <Text
      elementType="p"
      data-testid="terms-agreement"
      style={{
        fontWeight: 300, color: '#666666', fontSize: '14px', ...props,
      }}
    >
      {`By downloading and adding this pass to your ${walletType} Wallet, you agree to our `}
      <Link href={`${base}/terms-conditions/`}>Terms and Conditions</Link>
      {' and '}
      <Link href={`${base}/privacy-policy/`}>Privacy Policy</Link>
      .
    </Text>
  );
};

export default TermsAgreement;
