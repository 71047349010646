import { ComponentType } from 'react';

// This solution was based on https://www.codemzy.com/blog/fix-chunkloaderror-react

// This function triggers a page refresh when an import fails
// This is a way to handle "ChunkLoadError" right after a deploy
export const lazyRetry = (componentImport, name) => new Promise<{ default: ComponentType<any>; }>((resolve, reject) => {
  const storageKey = `retry-${name}-refreshed`;
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem(storageKey) || 'false',
  );

  // Try to import the component
  componentImport().then((component) => {
    window.sessionStorage.setItem(storageKey, 'false');

    return resolve(component);
  }).catch((error) => {
    // When an error happens, if the import for this component didn't trigger a refresh
    // Then it triggers a refresh
    if (! hasRefreshed && error.message === 'ChunkLoadError') {
      window.sessionStorage.setItem(storageKey, 'true');

      return window.location.reload();
    }

    // If this is the second refresh we throw the error so it can be handled
    return reject(error);
  });
});

export default lazyRetry;
