import React from 'react';
import styled from '@emotion/styled';
import { tick, cross } from '@simplybusiness/icons';
import { Icon } from '@simplybusiness/mobius';


import VisuallyHidden from './VisuallyHidden';

const StyledIcon = styled(Icon)`
  width: 10px;
  height: auto;
  margin-right: 16px;
  color: var(--color-bubblegum-500);
`;

const StyledIndicator = styled.div<Props>`
  color: ${props => props.positive ? 'var(--color-primary)' : 'var(--color-text-medium)'};
  margin-bottom: 4px;


  > div{
    display:inline-block;
    height: 10px;
    margin-right: 8px;
    margin-left: -10px;
  }
`;

interface Props{
  'data-testid'?: string,
  children?: React.ReactNode,
  positive?: boolean,
}

const Indicator = ({ children, 'data-testid': testId, positive = false }: Props): JSX.Element => (
  <StyledIndicator data-testid={testId} positive={positive}>
    <StyledIcon icon={positive ? tick : cross} />
    <VisuallyHidden>{positive ? 'Contains ' : 'Does not contain '}</VisuallyHidden>
    {children}
  </StyledIndicator>
);

export default Indicator;
