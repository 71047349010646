import React, { useEffect, useState } from 'react';
import {
  Link, LoadingIndicator,
} from '@simplybusiness/mobius';
import { fireAccountEvent } from 'services/TrackingService';
import { getAppleWalletLink } from '../../api/wallets';
import { TermsAgreement } from '../index';
import AppleWalletButtonStacked from './AppleWalletButtonStacked';
import AppleWalletButton from './AppleWalletButton';

type Props = {
  walletImageProps: {};
  loadingContainerProps: {};
  downloadedFrom: string;
  productType: string;
  showTerms?: boolean;
}

const fireAccountAddToWalletEvent = (
  downloadedFrom: string, productType: string,
): void => {
  fireAccountEvent('added_qr_code_to_wallet', {
    walletType: 'Apple',
    downloadedFrom,
    productType,
  });
};

const buttonImage = (coiPage: boolean, downloadedFrom: string, productType: string): JSX.Element => (
  coiPage ? (
    <AppleWalletButton
      data-testid="apple-wallet-img"
      onClick={() => fireAccountAddToWalletEvent(downloadedFrom, productType)}
    />
  ) : (
    <AppleWalletButtonStacked
      data-testid="apple-wallet-img"
      onClick={() => fireAccountAddToWalletEvent(downloadedFrom, productType)}
    />
  )
);

const AppleWalletContainer = (props: Props): JSX.Element => {
  const {
    walletImageProps, loadingContainerProps, downloadedFrom, productType, showTerms = false,
  } = props;

  const [walletHref, setWalletHref] = useState<string>('');

  useEffect(() => {
    getAppleWalletLink().then((url) => {
      setWalletHref(url);
    });
  }, []);

  return (
    walletHref ? (
      <>
        <Link href={walletHref} download="pass.pkpass">
          <div {...walletImageProps}>
            { buttonImage(showTerms, downloadedFrom, productType) }
          </div>
        </Link>
        { showTerms && <TermsAgreement textAlign="center" /> }
      </>
    ) : (
      <div {...loadingContainerProps}>
        <LoadingIndicator />
        <p style={{ margin: '0px' }}>
          Loading Apple Wallet...
        </p>
      </div>
    )
  );
};

export default AppleWalletContainer;
