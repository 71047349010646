import { Buffer } from 'buffer';
import { getApiBaseUrl } from 'config/api';
import { getJwT } from 'services/AuthenticationService';
import fetchWrapper from './fetch';

export const getGoogleWalletLink = async (): Promise<string> => {
  const res = await fetchWrapper(`${getApiBaseUrl()}/v3/wallet?pass_type=google_wallet`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
  });

  return res.wallet_url;
};

export const getAppleWalletLink = async (): Promise<string> => {
  const res = await fetchWrapper(`${getApiBaseUrl()}/v3/wallet?pass_type=apple_wallet`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
  });

  const buffer = Buffer.from(res.wallet_url, 'base64');
  const fileBlob = new Blob([buffer], { type: 'application/vnd.apple.pkpass' });

  return URL.createObjectURL(fileBlob);
};

export default { getGoogleWalletLink, getAppleWalletLink };
