import { Notifier } from '@airbrake/browser';
import { FetchError } from '../api/errors';

const airbrakeEnabledEnvironments = ['production', 'staging'];
const blockedAttributes = [/emailAddress/];

const isLocallySavedPage = window.location.origin.startsWith('file:');

const airbrakeNotifier = new Notifier({
  projectId: parseInt(process.env.AIRBRAKE_PROJECT_ID, 10),
  projectKey: process.env.AIRBRAKE_PROJECT_KEY,
  environment: process.env.BNW_ENV,
  keysBlocklist: blockedAttributes,
  remoteConfig: false,
  instrumentation: {
    fetch: false,
    xhr: false,
  },
});

const IGNORED_ERROR_TYPES = [
  'ChunkLoadError',
  'InvalidPasswordException',
  'NotAuthorizedException',
  'UnprocessableEntityError',
  'NS_ERROR_CONTENT_BLOCKED',
];

const IGNORED_ERROR_MESSAGES = [
  'Identifier \'originalPrompt\' has already been declared',
  'evaluating \'__gCrWeb',
  'evaluating \'a.O\'',
  'Not implemented on this platform',
  'can\'t redefine non-configurable property "userAgent"',
  'reading \'_avast_submit\'',
  'SymBrowser_ModifyWindowOpenWithTarget',
  'Talisman extension has not been configured yet',
  'reading \'firefoxSample',
  /request timed out/i,
  '198230182308109283091823098102938908128390',
  /NS_BINDING_ABORTED/,
];

const IGNORED_FILES = [
  /^((safari(-web)?|chrome|moz)-extension|webkit-masked-url|user-script|file):/,
  'dev.visualwebsiteoptimizer.com',
];

function isIgnoredErrorType(errorNotice) {
  return IGNORED_ERROR_TYPES.includes(errorNotice.errors?.[0]?.type)
    || IGNORED_ERROR_TYPES.includes(errorNotice.error?.name);
}

function isIgnoredErrorMessage(errorNotice) {
  const errorMessage = errorNotice.errors?.[0]?.message || '';

  return IGNORED_ERROR_MESSAGES.some((candidateMessage) => {
    if (candidateMessage instanceof RegExp) {
      return candidateMessage.test(errorMessage);
    }

    return errorMessage.includes(candidateMessage);
  });
}

function isIgnoredFile(errorNotice) {
  const errorBacktrace = errorNotice.errors?.[0]?.backtrace || [];

  return errorBacktrace.some(
    backtraceEntry => IGNORED_FILES.some((candidateFile) => {
      if (candidateFile instanceof RegExp) {
        return candidateFile.test(backtraceEntry.file);
      }

      return backtraceEntry.file.includes(candidateFile);
    }),
  );
}

function isNativeFetchError(errorNotice) {
  const error = errorNotice.errors?.[0];

  if (! error) return false;
  // If the error came from fetchWrapper call we should not blindly ignore it, but either fix it or suppress
  // in the place where the method is called
  if (error.type === 'FetchError') return false;

  return FetchError.isNativeFetchError({ name: error.type, message: error.message });
}

function isIrrelevantError(errorNotice) {
  return isIgnoredErrorType(errorNotice)
    || isIgnoredErrorMessage(errorNotice)
    || isIgnoredFile(errorNotice)
    || isNativeFetchError(errorNotice);
}

airbrakeNotifier.addFilter((errorNotice) => {
  if (isLocallySavedPage) return null;
  if (! airbrakeEnabledEnvironments.includes(process.env.BNW_ENV)) return null;
  if (isIrrelevantError(errorNotice)) return null;

  if (errorNotice.errors?.[0]?.backtrace?.[0]?.file) {
    /* eslint-disable no-param-reassign */
    [errorNotice.errors[0].backtrace[0].file] = errorNotice.errors[0].backtrace[0].file.split('?');
    /* eslint-enable no-param-reassign */
  }

  return errorNotice;
});

const getAirbrakeNotifier = () => airbrakeNotifier;

export default getAirbrakeNotifier;
