import { signOut } from 'services/AuthenticationService';
import newRelic from 'lib/newRelic';
import { UnprocessableEntityError, FetchError } from './errors';

const defaultHeaders = () => ({
  'Access-Control-Allow-Origin': '*', // TODO: This isn't how CORS works
  'Content-Type': 'application/json',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  'Business-Unit-Code': process.env.BUSINESS_UNIT_CODE!,
});

export const handleError = async (response: Response, url: String): Promise<void | Error> => {
  if (response.status === 401) {
    await signOut();
    window.location.reload();

    throw new Error('Unauthorized');
  }

  if (response.status === 422) {
    const responseBody = await response.json();

    throw UnprocessableEntityError.fromResponse(responseBody, url as string);
  }

  if (response.statusText === 'Network request failed') {
    newRelic.logError(new Error(`Network request failed for: ${url}`));
  }

  const body = await response.json();
  const message = body?.errors ? body.errors.join(', ') : response.statusText;

  throw new Error(message);
};

const handleRejection = (error) => {
  if (FetchError.isNativeFetchError(error)) throw FetchError.fromNativeError(error);

  throw error;
};

const fetchWrapper = <T=any> (
  input: string,
  options: Parameters<typeof fetch>[1] = { headers: {} },
  returnRaw = false,
  retryCount = 3,
  retryDelay = 300, // milliseconds
): Promise<T> => {
  const request = new Request(input, { ...options, headers: { ...defaultHeaders(), ...options.headers } });

  return fetch(request)
    .then((response) => {
      if (returnRaw) return response;
      if (! response.ok) return handleError(response, input);

      return response.json();
    }).catch((error) => {
      // Retry on error
      if (retryCount >= 0 && FetchError.isNativeFetchError(error)) {
        return new Promise(
          resolve => setTimeout(resolve, retryDelay),
        ).then(() => fetchWrapper(input, options, returnRaw, retryCount - 1))
          .catch(handleRejection);
      }

      return handleRejection(error);
    });
};

export default fetchWrapper;
