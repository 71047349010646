import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DropdownMenu from 'components/DropdownMenu';
import Logo from 'components/Logo';
import { signOut as signOutFromServer } from 'services/AuthenticationService';
import { getUser, isUserLoggedIn } from 'resources/user/selectors';
import trackingService from 'services/TrackingService';
import CallBlock from './CallBlock';
import MenuItem from './MenuItem';

const StyledHeader = styled.header`
  padding: var(--size-md);
  border-bottom: 1px solid var(--color-border-medium);
  background: var(--color-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type MenuRoute = {
  key: string,
  label: string,
  path: string,
  onClick?: () => void
}

const publicRoutes = [
  '/sign-in',
  '/sign-in/password',
  '/sign-in/token',
  '/reset-forgotten-password',
  '/forgot-password',
  '/create-password',
  '/confirm-email',
];

const callBlockRoutes = [
  '/sign-in',
  '/sign-in/password',
  '/forgot-password',
  '/sign-in/one-time-code',
  '/create-password',
];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(getUser);

  const shouldShowCallBlock = callBlockRoutes.includes(location.pathname);
  const shouldRenderMenu = ! publicRoutes.includes(location.pathname) && isUserLoggedIn(user);

  const signOut = async () => {
    trackingService.fireAccountEvent('account_signed_out');
    await signOutFromServer();
    navigate('/sign-in');
  };

  const menuRoutes: MenuRoute[] = [
    { key: 'home', label: 'Home', path: '/' },
    { key: 'account-details', label: 'My details', path: '/account-details' },
    { key: 'policy-search', label: 'Find & add policies', path: '/policy-search' },
    {
      key: 'payment-details',
      label: 'Payment details',
      path: '/payment-details',
      onClick: () => trackingService.fireAccountEvent('payment_details_link_clicked'),
    },
    {
      key: 'logout', label: 'Sign out', path: '/sign-in', onClick: signOut,
    },
  ];

  return (
    <StyledHeader data-testid="header">
      <Link to="/" aria-label="Simply Business"><Logo /></Link>
      {shouldRenderMenu && (
        <DropdownMenu
          onMenuAccessed={() => trackingService.fireAccountEvent('account_menu_accessed')}
        >
          {menuRoutes.map(({
            key, label, path, onClick,
          }) => (
            <MenuItem key={key} href={path} onClick={onClick}>
              <span className={`elem-desc__header-dropdown-${key}`} data-testid={`header-dropdown-${key}`}>
                {label}
              </span>
            </MenuItem>
          ))}
        </DropdownMenu>
      )}
      {shouldShowCallBlock && <CallBlock page={location.pathname} />}
    </StyledHeader>
  );
};

export default Header;
