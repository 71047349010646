import { contactCentre, ContactCentre } from 'config/contactCentre';

export class BusinessUnit {
  readonly code: string;

  readonly locale: string;

  readonly appId: string;

  readonly contactCentre: ContactCentre;

  constructor(code: string, locale: string, appId: string, cc: ContactCentre) {
    this.code = code;
    this.locale = locale;
    this.appId = appId;
    this.contactCentre = cc;
  }
}

export const currentBusinessUnit = (businessUnitCode: string = process.env.BUSINESS_UNIT_CODE): BusinessUnit => {
  const allBusinessUnits = {
    simplybusiness_uk: new BusinessUnit('simplybusiness_uk', 'en-GB', 'uk-account', contactCentre),
    simplybusiness_us: new BusinessUnit('simplybusiness_us', 'en-US', 'us-account', contactCentre),
  };

  const businessUnit = allBusinessUnits[businessUnitCode];

  if (! businessUnit) throw new Error(`Business unit '${businessUnitCode}' does not exist`);

  return businessUnit;
};

export default { currentBusinessUnit };
export type { SpecialCase, OpeningHoursForDay } from 'config/contactCentre';
