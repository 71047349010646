import React, { ReactElement, ComponentType } from 'react';
import styled from '@emotion/styled';

type IconProps = {
  size?: number;
  position: 'left' | 'right';
}

type Props = {
  children: any,
  iconLeft?: ComponentType<IconProps>,
  iconRight?: ComponentType<IconProps>,
  size?: number,
  justify?: string
}

type StyledWrapperProps = {
  justify?: string
}

type StyledChildrenProps = {
  isIconRight?: boolean;
  isIconLeft?: boolean;
}

const StyledWrapper = styled.span<StyledWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
`;

const StyledChildren = styled.span<StyledChildrenProps>`
  ${({ isIconRight, isIconLeft }) => `
    margin-right: ${isIconRight ? 'var(--size-sm)' : '0'};
    margin-left: ${isIconLeft ? 'var(--size-sm)' : '0'};
  `}
`;

const TextWithIcon = ({
  children, iconLeft: IconLeft, iconRight: IconRight, size, justify = 'center',
}: Props): ReactElement => (
  <StyledWrapper justify={justify}>
    {IconLeft && <IconLeft size={size} position="left" />}
    <StyledChildren isIconLeft={!! IconLeft} isIconRight={!! IconRight}>{children}</StyledChildren>
    {IconRight && <IconRight size={size} position="right" />}
  </StyledWrapper>
);

export default TextWithIcon;
