import { getApiBaseUrl, API_VERSION } from 'config/api';
import { getJwT } from 'services/AuthenticationService';
import fetchWrapper from './fetch';
import getAirbrakeNotifier from '../services/AirbrakeNotifierService';

export const resendInvite = async (token: string): Promise<Response> => {
  const uriEncodedToken = encodeURIComponent(token);
  const response = await fetchWrapper(`${getApiBaseUrl()}/${API_VERSION}/resend_confirmation?token=${uriEncodedToken}`,
    {
      method: 'POST',
    });

  return response;
};

type TokenSource = 'sign_up' | 'confirm_email_change' | 'reset_password';

/**
 * @param token the encrypted token present in the link
 * @param tokenSource used to tell the next_step endpoint the purpose of the link decryption as they behaves differently
 */
export const decryptToken = async (token: string, tokenSource: TokenSource):
  Promise<any> => {
  const uriEncodedToken = encodeURIComponent(token);

  if (uriEncodedToken === 'undefined') {
    getAirbrakeNotifier().notify({
      error: "Sending 'undefined' to API",
      params: { rawToken: token, uriEncodedToken },
    });
  }
  const response = await fetchWrapper(
    `${getApiBaseUrl()}/${API_VERSION}/next_step?token=${uriEncodedToken}&token_source=${tokenSource}`,
  );

  return response;
};

interface DecryptEmailTokenResponse {
  // eslint-disable-next-line camelcase
  email_address: string;
}

/**
 * @param token the encrypted token present in the link
 */
export const decryptEmailToken = async (token: string): Promise<DecryptEmailTokenResponse> => {
  const uriEncodedToken = encodeURIComponent(token);

  return fetchWrapper(
    `${getApiBaseUrl()}/${API_VERSION}/decrypt_email_token?token=${uriEncodedToken}`,
  );
};

export const signUp = async ({
  password,
  token,
}): Promise<Response> => {
  const response = await fetchWrapper(`${getApiBaseUrl()}/v3/accounts?t=${token}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    body: JSON.stringify({
      password: password.trim(),
    }),
  });

  return response;
};

export const resendEmailVerification = async (token: string): Promise<Response> => {
  const uriEncodedToken = encodeURIComponent(token);
  const response = await fetchWrapper(
    `${getApiBaseUrl()}/${API_VERSION}/resend_change_email_verification?token=${uriEncodedToken}`,
    {
      method: 'POST',
    },
  );

  return response;
};

export default { decryptToken, resendEmailVerification };
