import Auth from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  USER__DELETE_CURRENT_JWT_TOKEN,
  USER__STORE_CURRENT_JWT_TOKEN,
  storeCurrentUserInfo,
} from 'resources/user/ducks';
import { getUser } from 'resources/user/selectors';
import store from 'boot/store';
import newRelic from 'lib/newRelic';
import { resetPolicies } from 'resources/policies/ducks';

let cognitoUser: CognitoUser;

const sanitisedPassword = (password: string): string => password.trim();

const sanitisedEmail = (email: string): string => email.toLowerCase();

export const verifyChangedEmail = async (code: string): Promise<string> => (
  Auth.verifyCurrentUserAttributeSubmit('email', code)
);

export const signIn = async (email: string, password: string, checkVerifiedEmail = false): Promise<void> => {
  await Auth.signIn(sanitisedEmail(email), sanitisedPassword(password));

  const currentUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });

  if (checkVerifiedEmail) {
    const emailVerified = currentUserInfo.attributes.email_verified;
    const normalizedEmailVerified = emailVerified.toString().toLowerCase();

    if (normalizedEmailVerified !== 'true') {
      throw new Error('This email address has not been verified yet');
    }
  }
  store.dispatch({
    type: USER__STORE_CURRENT_JWT_TOKEN,
    payload: currentUserInfo,
  });
};

export const sendEmailWithCode = async (email: string): Promise<void> => {
  cognitoUser = await Auth.signIn(sanitisedEmail(email));
};

export const signInWithOneTimeCode = async (code: number | string): Promise<void> => {
  await Auth.sendCustomChallengeAnswer(cognitoUser, code.toString());
  const currentUserInfo = await Auth.currentAuthenticatedUser();

  store.dispatch({
    type: USER__STORE_CURRENT_JWT_TOKEN,
    payload: currentUserInfo,
  });
};

export const resetForgottenPassword = async (
  email: string,
  code,
  password: string,
): Promise<string> => (Auth.forgotPasswordSubmit(email, code, sanitisedPassword(password)));

export const refreshUserSession = async (): Promise<void> => Auth.currentAuthenticatedUser()
  .then(user => user.refreshSession(
    user.signInUserSession.refreshToken,
    (err, session) => {
      storeCurrentUserInfo({ signInUserSession: session });
    },
  ))
  .catch((err) => {
    newRelic.logError(err);
  });

export const getJwT = (): string | null => {
  const userState = getUser(store.getState());

  return userState.token;
};

export const deleteUserInfo = (): void => {
  store.dispatch({
    type: USER__DELETE_CURRENT_JWT_TOKEN,
  });
};

export const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut();
  } finally {
    deleteUserInfo();
    store.dispatch(resetPolicies());
  }
};

export const getUserInfo = (): Object => getUser(store.getState());
