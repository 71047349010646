import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from './sagas';

let composeEnhancers = compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware,
];

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  composeEnhancers = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window
  // eslint-disable-next-line
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'SB: Account UK', instanceId: 'uk_account' })
    : compose;
}

const enhancers = applyMiddleware(
  ...middleware,
);

export const composedEnhancers = composeEnhancers(enhancers);

// @ts-ignore
const store = createStore(
  rootReducer,
  composedEnhancers,
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

sagaMiddleware.run(rootSaga);

export default store;
