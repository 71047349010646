import React from 'react';
import { Link } from '@simplybusiness/mobius';
import styled from '@emotion/styled';
import { currentBusinessUnit } from 'lib/businessUnit';

const ccPhoneNumber = currentBusinessUnit().contactCentre.phoneNumbers.generalEnquiries;


const customerNotEligibleMessage = (
  <div>
    Something's gone wrong - a technical issue is stopping us from setting up your account. You can still
    {' '}
    <a
      href={`${process.env.CUSTOMER_ACCOUNT_DOCUMENTS_SERVER_URI}/support/documents/request`}
      title="Request your documents"
    >
      request your documents
    </a>
    {' '}
    using the policy number on your confirmation email.
  </div>
);


const TelephoneLink = styled(Link)`
  display: inline-block;
  color: inherit;
`;


export const malformedSignUpUrl = (
  <div>
    {'This link is invalid, please check your email and try again. '
    + 'If you are still experiencing this issue please call us on '}
    <TelephoneLink href={`tel:${ccPhoneNumber.replace(/\s/g, '')}`}>{ccPhoneNumber}</TelephoneLink>
  </div>
);


export default {
  email: {
    invalid: 'Email is not valid',
    required: 'Email is required',
  },
  password: {
    lowerCase: 'Must include at least one lower case character',
    upperCase: 'Must include at least one upper case character',
    number: 'Must include at least one number',
    minLength: 'Must be at least 12 characters',
    required: 'Password is required',
    generic: "Your password doesn't meet all the requirements, please check and try again.",
    unsafePassword: "This password isn't secure enough – choose another one.",
    incorrectCurrentPassword: 'The current password you entered is incorrect. Please try again.',
  },
  code: {
    required: 'Code is required',
  },
  unpredictableError: "Something's gone wrong – try again in a few minutes.",
  customerNotEligibleMessage,
  generic: "Something's gone wrong – try again in a few minutes.",
  malformedSignUpUrl,
  policySearch: {
    notLinkedToEmail: 'Policy number not linked to this email address.',
    notFound: 'Policy number not found.',
  },
};
