import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Text } from '@simplybusiness/mobius';


const RotatingIcon = styled('svg')`
  animation: rotate 0.8s linear infinite;
  position: relative;
  width: 64px;
  height: 64px;
  color: var(--color-primary);

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

type Props = {
  withText?: boolean,
  animationDelay?: number,
  margin?: string
}

type WrapperProps = {
  animationDelay: number | undefined,
  margin: string | undefined
}

const Wrapper = styled('div')`
  width: 100%;
  margin: ${({ margin }: WrapperProps) => margin};
  display: flex;
  flex-direction: column;
  align-items: center;
  
  /* no spinner flickering for fast load times */
  visibility: hidden;
  animation: 0.3s linear ${({ animationDelay }: WrapperProps) => animationDelay}s forwards delayedShow;
  
  @keyframes delayedShow {
    to {
      visibility: visible;
    }
  }
`;

const StyledText = styled(Text)`
  margin: 16px 0 0;
`;

const Loading = ({ withText, animationDelay, margin }: Props): ReactElement => (
  <Wrapper animationDelay={animationDelay} margin={margin}>
    <RotatingIcon
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      role="img"
    >
      <title>Loading icon</title>
      <circle
        strokeDasharray="164.93361431346415 63.97787143782138"
        strokeWidth="5"
        stroke="currentColor"
        fill="none"
        cx="50"
        cy="50"
        r="35"
      />
    </RotatingIcon>
    {withText && <StyledText>Loading...</StyledText>}
  </Wrapper>
);

Loading.defaultProps = {
  withText: true,
  animationDelay: 1, // secs
  margin: '88px 0 0',
};

export default Loading;
