import createCache from '@emotion/cache';

const element = document.querySelector<HTMLMetaElement>('meta[name="CSP-NONCE"]');

const emotionCache = createCache({
  key: 'emotion-cache-key',
  nonce: element?.content,
});

export default emotionCache;
