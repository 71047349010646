import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

type Props = {
  size?: number,
  position: 'left' | 'right',
}

const Wrapper = styled('span', {
  shouldForwardProp: (prop: string) => isPropValid(prop) && prop !== 'size',
})`
  display: flex;
  
  ${({ size, position }: Props) => `
    width: ${size}px;
    height: ${size}px;
    transform: rotate(${position === 'left' ? '180' : '0'}deg);
  `}
`;

const Arrow = ({ size, position }: Props): ReactElement => (
  <Wrapper size={size} position={position}>
    <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.25 5H8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.25 8.5L8.75 5L5.25 1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Wrapper>
);

Arrow.defaultProps = {
  size: 8,
};

export default Arrow;
