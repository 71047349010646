import { getApiBaseUrl, API_VERSION } from 'config/api';
import { getJwT } from 'services/AuthenticationService';
import { ContactRecordConflict } from 'scenes/PolicySearch/components/PolicyFound';
import Policy from 'models/Policy';
import fetchWrapper from './fetch';
import newRelic from '../lib/newRelic';

/*
  The purpose of this method is a normalised object.
  I.e. in the front-end we handled a specific set of policy state that is a subset of what we handle in the back-end
*/
const toPolicy = (policyObject): Policy => new Policy(policyObject);

const parseRecordConflicts = conflicts => Object.keys(conflicts).map(key => ({
  recordName: key,
  recordOptions: conflicts[key],
}));

export const getAll = async (): Promise<Policy[]> => {
  const response = await fetchWrapper(`${getApiBaseUrl()}/${API_VERSION}/policies`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
  });

  const policiesMissingSegments = response.filter(p => p.segments.length === 0).map(p => p.id);

  // Notify NewRelic in the event that one or more policies do not contain segments
  if (policiesMissingSegments.length > 0) {
    newRelic.logError(
      new Error(`One or more policies returned from AAG with no segments: ${policiesMissingSegments.join(', ')}`),
    );
  }

  return response.filter(p => p.segments.length > 0).map(toPolicy);
};

export enum GetPolicyStatus{
  Succcess, ValidationError, Failure
}

interface GetPolicyResponse{
  status: GetPolicyStatus,
  policy: Policy | null,
  validationErrors: string[],
  contactRecordConflicts: ContactRecordConflict[],
  verifyPostcode?: boolean
}
interface GetCancellationEligibilityResponse{
  status: number,
}

export const get = async (
  policyNumber: string,
  verifyingPostcode?: string,
): Promise<GetPolicyResponse> => {
  const res = await fetchWrapper(`${getApiBaseUrl()}/v3/policies/${policyNumber}`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    method: 'POST',
    body: JSON.stringify({
      verifyingPostcode,
    }),
  }, true);

  const data = await res.json();

  if (res.ok) {
    return {
      status: GetPolicyStatus.Succcess,
      policy: toPolicy(data.policy),
      contactRecordConflicts: parseRecordConflicts(data.correctable),
      validationErrors: [],
    };
  }

  if (res.status === 400) {
    const { invalid_fields: fields } = data;

    return {
      status: GetPolicyStatus.ValidationError,
      policy: null,
      validationErrors: fields,
      contactRecordConflicts: [],
      verifyPostcode: data.verify_postcode,
    };
  }

  return {
    status: GetPolicyStatus.Failure,
    policy: null,
    validationErrors: [],
    contactRecordConflicts: [],
  };
};

export const getCancellationEligibility = async (
  policyNumber: string,
): Promise<GetCancellationEligibilityResponse> => {
  const res = await fetchWrapper(`${getApiBaseUrl()}/v3/policies/${policyNumber}/cancellations/eligibility`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    method: 'GET',
  }, true).catch((error) => {
    newRelic.logError(
      new Error(`Error fetching cancellation eligibility for policy ${policyNumber}: ${error.message}`),
    );
  });

  return res;
};


interface RefundInfo{
  refundAmount: number,
  currency: string,
  adminFee: number,
}

interface RefundTarget{
  cardLast4Digits: string,
  cardBrand: string,
}

export interface GetSettlementInformationResponse{
  id: string,
  refundInfo: RefundInfo,
  refundTarget: RefundTarget,
}

export const getSettlementInformation = async (
  policyNumber: string,
  effectiveDate: string,
  cancellationReason: string,
  cancellationReasonType: string,
): Promise<GetSettlementInformationResponse> => {
  const queryParams = new URLSearchParams({
    effective_date: effectiveDate,
    cancellation_reason: cancellationReason,
    cancellation_reason_type: cancellationReasonType,
  }).toString();

  const res = await fetchWrapper(`${getApiBaseUrl()}/v3/policies/${policyNumber}/cancellations/`
  + `settlement?${queryParams}`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    method: 'GET',
  });

  return res;
};

export const cancelPolicy = async (
  cancellationRequestId: string, effectiveDate: string, cancellationReason: string, policyNumber: string,
) => {
  const res = await fetchWrapper(`${getApiBaseUrl()}/v3/policies/cancellations/cancellation`, {
    headers: {
      Authorization: `Bearer ${getJwT()}`,
    },
    body: JSON.stringify({
      cancellation_request_id: cancellationRequestId,
      effective_date: effectiveDate,
      cancellation_reason: cancellationReason,
      policy_number: policyNumber,
    }),
    method: 'POST',
  });

  return res;
};


export async function getMtaEligibility(policyNumber: string): Promise<string> {
  const opts = { headers: { Authorization: `Bearer ${getJwT()}` } };

  return fetchWrapper(`${getApiBaseUrl()}/v3/policies/${policyNumber}/mta`, opts);
}

export default {
  getAll, get, getCancellationEligibility, getSettlementInformation, cancelPolicy,
};
