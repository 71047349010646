/*
  This wrapper reads from businessRulesData.json which is generated at build-time
  by calling BRaas (Business Rules as a service, https://github.com/simplybusiness/rambdas).

  Currently we set the default phone number as is not provided by BRaaS yet.
*/
import businessRules from './businessRulesData.json';

export interface SpecialCase {
  open: string,
  close: string,
  date: string
}

export interface OpeningHoursForDay {
  open: string,
  close: string
}

interface OpeningHours {
  monday?: OpeningHoursForDay,
  tuesday?: OpeningHoursForDay,
  wednesday?: OpeningHoursForDay,
  thursday?: OpeningHoursForDay,
  friday?: OpeningHoursForDay,
  weekday?: OpeningHoursForDay,
  saturday: OpeningHoursForDay,
  sunday: OpeningHoursForDay
}

export interface ContactCentre {
  openingHours: OpeningHours,
  specialCases: SpecialCase[],
  phoneNumbers: { generalEnquiries: string, claim: string },
}

export const getOpeningHours = (): OpeningHours => {
  const { ...openingHours } = businessRules?.data?.opening_hours || {};

  return openingHours;
};
export const getSpecialCases = (): Array<SpecialCase> => businessRules?.data?.opening_hours?.special_cases || [];
export const getGeneralEnquiriesPhoneNumber = (): string => businessRules?.data?.address?.phone || '0333 014 6683';
export const getClaimPhoneNumber = (): string => '0333 207 0560';

export const contactCentre = {
  openingHours: getOpeningHours(),
  specialCases: getSpecialCases(),
  phoneNumbers: {
    generalEnquiries: getGeneralEnquiriesPhoneNumber(),
    claim: getClaimPhoneNumber(),
  },
};
