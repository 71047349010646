import React, {
  Ref, forwardRef, ReactNode, MouseEventHandler,
} from 'react';
import styled from '@emotion/styled';
import { Icon, DropdownMenu as MobiusDropdownMenu } from '@simplybusiness/mobius';
import { chevronDown, chevronUp } from '@simplybusiness/icons';

export interface Props {
  align?: string,
  children: ReactNode,
  onMenuAccessed?: () => void
}

const StyledMobiusDropdownMenu = styled(MobiusDropdownMenu)`
  width: 100%;

  .mobius\\/DropdownMenuItem.--is-active {
    border-left-color: var(--color-primary);
  }
`;

const StyledCustomTrigger = styled.button`
  width: 100%;
  text-align: right;
`;

interface CustomTriggerProps {
  open?: boolean,
  label?: string,
  onClick?: (event: MouseEventHandler<HTMLButtonElement>) => void;
  onMenuAccessed: () => void
}

const CustomTrigger = forwardRef((props: CustomTriggerProps, ref: Ref<HTMLButtonElement>) => {
  const {
    open, onClick, onMenuAccessed, ...otherProps
  } = props;
  const triggerLabel = ! open ? 'Account' : 'Close';

  const handleClick = (event) => {
    onMenuAccessed();
    onClick?.(event);
  };

  return (
    <StyledCustomTrigger type="button" ref={ref} onClick={handleClick} {...otherProps}>
      {triggerLabel}
      {' '}
      <Icon icon={open ? chevronUp : chevronDown} />
    </StyledCustomTrigger>
  );
});

const DropdownMenu = (props) => {
  const { children, onMenuAccessed } = props;
  const handleToggle = () => {
    onMenuAccessed?.();
  };

  return (
    <StyledMobiusDropdownMenu trigger={
      <CustomTrigger onMenuAccessed={handleToggle} />}
    >
      {children}
    </StyledMobiusDropdownMenu>
  );
};

export default DropdownMenu;
