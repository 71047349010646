import { UnknownAction } from 'redux';
import type Policy from 'models/Policy';

export const POLICIES__FETCH = 'POLICIES__FETCH';
export const POLICIES__FETCH_COMPLETE = 'POLICIES__FETCH_COMPLETE';
export const POLICIES__RESET = 'POLICIES__RESET';

export type PoliciesState = {policies: Policy[], loading: boolean}
export const defaultPoliciesState: PoliciesState = { policies: [], loading: false };

interface Action extends UnknownAction {
  type: string;
  payload?: any;
  meta?: Object;
  error?: boolean;
}

export const fetchPolicies = (): Action => ({
  type: POLICIES__FETCH,
});

export const resetPolicies = (): Action => ({
  type: POLICIES__RESET,
});

export const fetchPoliciesComplete = (policies: Object | Error): Action => ({
  type: POLICIES__FETCH_COMPLETE,
  payload: policies,
  error: policies instanceof Error,
});

export default (prevState = defaultPoliciesState, action) => {
  switch (action.type) {
    case POLICIES__FETCH: {
      return { ...prevState, loading: true };
    }

    case POLICIES__FETCH_COMPLETE: {
      if (action.error) {
        return { ...prevState, loading: false };
      }
      const { payload: policies } = action;

      return { loading: false, policies };
    }

    case POLICIES__RESET: {
      return defaultPoliciesState;
    }

    default:
      return prevState;
  }
};
