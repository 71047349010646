import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format } from 'date-fns';
import { enUS as enLocale } from 'date-fns/locale/en-US';
import { currentBusinessUnit } from 'lib/businessUnit';
import enGB from './en-GB/translation.json';
import enUS from './en-US/translation.json';

const supportedLocales = {
  'en-GB': {
    locale: enLocale,
  },
  'en-US': {
    locale: enLocale,
  },
};

const fallbackLng = 'en-GB';

const setupI18n = (locale = currentBusinessUnit().locale): void => {
  i18n
    .use(initReactI18next)
    .init({
      lng: locale,
      fallbackLng,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format(value, frmt, lng) {
          if (value instanceof Date) {
            if (! frmt) {
              throw new Error('Dates must have a format specified');
            }

            return format(value, frmt, {
              locale: supportedLocales[lng || fallbackLng].locale,
            });
          }

          return value;
        },
      },
      resources: {
        'en-GB': {
          translation: enGB,
        },
        'en-US': {
          translation: enUS,
        },
      },
    });
};

export { setupI18n as default };
